import React, { Component } from "react";
import "../css/style.css";
import { Button, Row, Col } from "react-bootstrap";
import ReactDropzone from "react-dropzone";
import RecordingAudio from "../components/RecordingAudio";
import LoadingGif from "../assets/loading.gif";

class Identification extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            isRecording: false,
            show: props.modal,
            inputName: "",
            inputFileName: "",
            namaFile: "",
            files: "",
            items_record: [],
            gender: "male",
            fileAudio: "",
            identifyName: "",
            identifyGender: "",
            emptyResult: ""
        };

        this.getRecordingStatus = this.getRecordingStatus.bind(this);
        this.getTranscriptionStatus = this.getTranscriptionStatus.bind(this);
        this.getAudioPlayStatus = this.getAudioPlayStatus.bind(this);
        this.getAudioStatus = this.getAudioStatus.bind(this);

        this.handleChangeGender = this.handleChangeGender.bind(this);
        this.getResultBlob = this.getResultBlob.bind(this);
        this.clearStateRecord = this.clearStateRecord.bind(this);
    }

    getRecordingStatus(value) {
        console.log("s2t -> recording", value);
        if (this.state.isRecording !== value) {
          this.setState({ isRecording: value }, value => {
            if (value) {
              this.setState({
                transcription: {
                  status: false,
                }
              });
            }
          });
        }
    }

    getTranscriptionStatus(value) {
        console.log("s2t -> Transcription", value);
        var currentTime = Date.now() / 1000;
        if (this.state.text !== "" && (currentTime - this.state.lastTextReceivedTime > 4 || this.state.isUploadFile)) {
          this.setState({
            isLoading: false,
            // text: this.state.text + "\n\n" + value,
            text: this.state.text,
            lastTextReceivedTime: currentTime,
            isUploadFile: false
          });
        } else {
          this.setState({
            isLoading: false,
            // text: this.state.text + " " + value,
            text: this.state.text,
            lastTextReceivedTime: currentTime
          });
        }
    }

    getAudioPlayStatus(value) {
        console.log("audio play status", value);
    }

    getAudioStatus(value) {
        console.log("audio status", value)
        this.setState({
          audioSrc: value
        })
    }

    componentWillReceiveProps(nextProps){
    	if(this.state.show!==nextProps.modal){
      	this.setState({show: nextProps.modal})
      }
    }

    handleInputChange = (e) => {
        e.preventDefault();
        console.log('Tes', e, e.target.name, e.target.value)
        // let name = e.target.name;
        // let value = e.target.value;
    
        this.setState({
            inputName: e.target.value,
            inputFileName: this.state.inputFileName
        })
    };

    onPreviewDrop = (files) => {
        console.log("input filename", files[0].preview)
        this.setState({
            fileAudio: files[0],
            files: files,
            inputFileName: files[0].preview,
            namaFile: files[0].name
        });
    
    }

    handleFormSubmit = (e) => {
        console.log("form submitted", this.state.inputName, this.state.inputFileName)
        this.props.resultModal = this.state.inputName.concat(this.state.inputFileName)
    }

    identifyAudio() {
        this.setState({
            isLoading: true
        })
        console.log("submit button identify",this.state.files, this.state.fileAudio, this.state.gender, this.state.inputName, this.state.inputFileName, this.state.namaFile)
        
        const fdIdentify = new FormData();
        fdIdentify.append('voice', this.state.fileAudio);
        
        fetch('/v1/sid/set_a/identify',
        {
            method: 'POST',
            body: fdIdentify,
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log("identify result", result, Object.keys(result).length)
                
                if (Object.keys(result).length > 1) {
                    if (result['status'] === 200) {
                        if (result['result'].length === 0) {
                            this.setState({
                                emptyResult: "Maaf suara tersebut tidak dikenali"
                            })
                        } else {
                            this.setState({
                                identifyName: result['result'][0].name,
                                identifyGender: result['result'][0].gender
                            })
                        }
                    } else {
                        console.log("voice err", result['message'])
                        alert(result['message'])
                    }
                } else {
                    console.log("bad request", result['message'])
                    alert(result['message'])
                }

                this.setState({
                    isLoading: false
                })
            },
        );
    }

    handleChangeGender(val) {
        console.log("gender: ", val.target.value)
        this.setState({ gender: val.target.value });
    }

    clearState() {
        console.log("clear")
        this.setState({
            identifyName: "",
            identifyGender: "",
            emptyResult: "",
            fileAudio: "",
            files: "",
            inputFileName: "",
            namaFile: ""
        })
    }

    clearStateRecord() {
        console.log("clear record")
        this.setState({
            isRecording: true,
        })
        this.setState({
            identifyName: "",
            identifyGender: "",
            emptyResult: "",
            fileAudio: "",
            files: "",
            inputFileName: "",
            namaFile: ""
        })
    }

    getResultBlob(blob) {
        console.log("get blob ", blob)

        const fdIdentify = new FormData();
        fdIdentify.append('voice', blob);
        
        fetch('/v1/sid/set_a/identify',
        {
            method: 'POST',
            body: fdIdentify,
            headers: {
                'x-api-key': 'ProsaCerdas@bandung10',
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log("identify result", result, Object.keys(result).length)
                if (Object.keys(result).length > 1) {
                    if (result['status'] === 200) {
                        if (result['result'].length === 0) {
                            this.setState({
                                emptyResult: "Maaf suara tersebut tidak dikenali"
                            })
                        } else {
                            this.setState({
                                identifyName: result['result'][0].name,
                                identifyGender: result['result'][0].gender
                            })
                        }
                    } else {
                        console.log("voice err", result['message'])
                        alert(result['message'])
                    }
                } else {
                    console.log("bad request", result['message'])
                    alert(result['message'])
                }
                this.setState({
                    isRecording: false,
                })
            },
        );
    }

    render() {
        return (
            <div>
                <div style={{ marginTop: '2rem' }}>
                    <Row style={{ marginTop: '25px', width: '70%'}}>
                        <div>
                            <Col lg={12} sm={12} xs={12} style={{display: 'flex', paddingRight: '0px'}}>
                                {this.state.isRecording ?
                                    <div style={{paddingRight: '30px'}}>
                                        <ReactDropzone
                                            multiple={false}
                                            accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg"
                                            onDrop={this.onPreviewDrop}
                                            style={reactDropzoneStyle}
                                            disabled
                                        >
                                        <div className="button-rec-hover">
                                            <button className="button-circle-disabled">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24px" height="24px" viewBox="-0.5 -0.5 71 71"><defs/>
                                                <g>
                                                    <path d="M 10 9.25 L 37.67 9.25 L 37.67 0 L 60 25 L 37.67 50 L 37.67 40.75 L 10 40.75 L 10 25 Z" fill="#ffffff" stroke="none" transform="rotate(270,35,25)" pointerEvents="none"/>
                                                    <rect x="0" y="60" width="70" height="10" fill="#ffffff" stroke="none" pointerEvents="none"/>
                                                </g>
                                                </svg>
                                                <span onClick={this.clearState.bind(this)} className="button-font">Unggah Audio</span>
                                            </button>
                                        </div>
                                        </ReactDropzone>
                                    </div>
                                :
                                    <div style={{paddingRight: '30px'}}>
                                        <ReactDropzone
                                            multiple={false}
                                            accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg"
                                            onDrop={this.onPreviewDrop}
                                            style={reactDropzoneStyle}
                                        >
                                        <div className="button-rec-hover">
                                            <button className="button-circle button-style">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24px" height="24px" viewBox="-0.5 -0.5 71 71"><defs/>
                                                <g>
                                                    <path d="M 10 9.25 L 37.67 9.25 L 37.67 0 L 60 25 L 37.67 50 L 37.67 40.75 L 10 40.75 L 10 25 Z" fill="#ffffff" stroke="none" transform="rotate(270,35,25)" pointerEvents="none"/>
                                                    <rect x="0" y="60" width="70" height="10" fill="#ffffff" stroke="none" pointerEvents="none"/>
                                                </g>
                                                </svg>
                                                <span onClick={this.clearState.bind(this)} className="button-circle-font button-font">Unggah Audio</span>
                                            </button>
                                        </div>
                                        </ReactDropzone>
                                    </div>
                                }
                                
                                <div onClick={this.clearStateRecord.bind(this)}>
                                    <RecordingAudio
                                        category="identification"
                                        resultBlob={this.getResultBlob}
                                        // clearState={this.clearStateRecord}
                                    />
                                </div>
                            </Col>
                        </div>
                    </Row>
                    {this.state.files.length > 0 &&
                        <div style={{marginTop: '20px'}}>
                            {this.state.files.map((file) => (
                            <div
                                alt="Preview"
                                key={file.preview}
                            >
                                File: {file.name}
                                <br/>
                            </div>
                            ))}
                        </div>
                    }
                </div>
                {this.state.identifyName && this.state.identifyGender ?
                    <div style={{marginTop: '20px'}}>
                        <div className="button-circle-font" style={{fontSize: '16px'}}>Nama: {this.state.identifyName}</div>
                        {/* Nama: {this.state.identifyName} */}
                        {/* <br/> */}
                        <div className="button-circle-font" style={{fontSize: '16px'}}>Gender: {this.state.identifyGender}</div>
                    </div>
                : ""}
                {this.state.emptyResult ?
                    <div style={{marginTop: '20px'}}>
                        {this.state.emptyResult}
                    </div>
                : ""}
                { this.state.files.length === 0 ?
                    <Button
                        bsStyle="primary"
                        style={{marginTop:"4rem", marginLeft: "0rem", paddingLeft: "16px", paddingRight: "16px"}}
                        disabled
                    >
                        Identifikasi
                    </Button>
                    :
                    <div>
                        <Button
                            bsStyle="primary"
                            style={{marginTop:"4rem", marginLeft: "0rem", paddingLeft: "16px", paddingRight: "16px"}}
                            onClick={this.identifyAudio.bind(this)}
                        >
                            Identifikasi
                        </Button>
                        {this.state.isLoading ? (
                            <span>
                                <img
                                    src={LoadingGif}
                                    alt="loading"
                                    className="loading-gif-tts"
                                    style={{marginTop: '4rem'}}
                                />
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                }
                {/* <Button
                  className="button-style button-font"
                  style={{marginTop:"4rem", marginLeft: "0rem", paddingLeft: "16px", paddingRight: "16px"}}
                  onClick={this.identifyAudio.bind(this)}
                >
                  Identifikasi
                </Button> */}
            </div>
        );
    }
}

const reactDropzoneStyle = {
    borderStyle: 'none',
    cursor: 'pointer'
};

export default Identification;
