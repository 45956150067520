import {Component} from "react";
import Dropzone from 'react-dropzone'
import React from "react";

class Uploader extends Component {
    constructor(props) {
        super();
        this.state = {
            accepted: [],
            rejected: [],
            isLoading: false,
            text: "",
            audioBlob: "",
            filename: "",
        }
        this.handleUpload = this.handleUpload.bind(this);
    }

    onPreviewDrop = (accepted, rejected) => {
        console.log('file accepted ', accepted, accepted.length)
        if (accepted.length < 1) {
            alert("File Tidak Valid");
        } else {
            console.log("accepted ", accepted)

            this.props.acceptedFiles(accepted)

        }
    }

    handleUpload() {
        console.log("handle upload file status ", this.props.uploadFileStatus())
        this.props.uploadFileStatus();
    }

    render() {
        return(
            <div className="uploadInline">
                <Dropzone
                    multiple={false}
                    style={dropzoneStyle}
                    accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg"
                    disabled={this.state.isLoading || this.props.isRecording}
                    onDrop={this.onPreviewDrop}
                >
                    { this.state.isLoading || this.props.isRecording ?
                        <div className="button-rec-hover" >
                            <button disabled className="button-circle-disabled">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" viewBox="-0.5 -0.5 71 71"><defs/>
                                <g>
                                    <path d="M 10 9.25 L 37.67 9.25 L 37.67 0 L 60 25 L 37.67 50 L 37.67 40.75 L 10 40.75 L 10 25 Z" fill="#ffffff" stroke="none" transform="rotate(270,35,25)" pointerEvents="none"/>
                                    <rect x="0" y="60" width="70" height="10" fill="#ffffff" stroke="none" pointerEvents="none"/>
                                </g>
                                </svg>
                                <span className="button-font">Unggah Audio</span>
                            </button>
                        </div>
                    :
                        <div className="button-rec-hover" >
                            <button onClick={this.handleUpload} className="button-circle button-style">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" viewBox="-0.5 -0.5 71 71"><defs/>
                                <g>
                                    <path d="M 10 9.25 L 37.67 9.25 L 37.67 0 L 60 25 L 37.67 50 L 37.67 40.75 L 10 40.75 L 10 25 Z" fill="#ffffff" stroke="none" transform="rotate(270,35,25)" pointerEvents="none"/>
                                    <rect x="0" y="60" width="70" height="10" fill="#ffffff" stroke="none" pointerEvents="none"/>
                                </g>
                                </svg>
                                <span className="button-circle-font button-font">Unggah Audio</span>
                            </button>
                        </div>
                    }
                </Dropzone>
            </div>
        );
    }
}

const dropzoneStyle = {
    height: "1em",
    border: "1px #dbe2e5",
    float:"left"
};

export default Uploader
