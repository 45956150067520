import React, { Component } from 'react';

class Footer extends Component {
    constructor(props) {
        super();
        this.state = {
            currentActive: props.name
        };
        this.setActive = this.setActive.bind(this);
    }
    setActive(category) {
        this.setState({
            currentActive: category
        });

    }
    render () {
        return (
            <div>
            {/* // <div className="footer"> */}
                {/* &copy; Copyright Prosa.ai */}
            </div>
        )
    }
}

export default Footer