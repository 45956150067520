import React, { Component } from "react";
import Header from "../components/Header";
import Verification from "../components/Verification";
import Identification from "../components/Identification";
import "../css/style.css";
import Tabs from '../components/Tabs';

class SpeakerBiometric extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputName: "",
      inputFilename: "",
      files: [],
      items_record: [],
      isLoading: false,
      isRecording: false,
      buttonClick: 0
    };
  }

  onPreviewDrop = (files) => {
    const inputFilename = document.getElementById('inputFilename')
    console.log("input filename", inputFilename, files[0].preview)
    inputFilename.value = files[0].preview
    console.log("input filename", inputFilename)
    this.setState({
      files: this.state.files.concat(files),
      inputFilename: files[0].preview
    });

  }

  handleInputChange = (e) => {
    e.preventDefault();
    console.log('Tes', e, e.target.name, e.target.value, this.state.inputFilename)
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value,
      inputFilename: this.state.inputFilename
    })
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    let items_record = [...this.state.items_record];

    items_record.push({
      inputName: this.state.inputName,
      inputFilename: this.state.inputFilename,
    });

    this.setState({
      items_record,
      inputName: '',
      inputFilename: ''
    });
  }

  getResultVerification() {
    const containerResult = document.getElementsByClassName('container-update-result')
    console.log("NANA", containerResult[0])
    containerResult[0].style.display = "block"
  }
  
  render() {
    const styleCursor = {
      cursor: 'pointer',
    };

    return (
      <div>
        <Header name={"speakerbiometric"} />
        <div className="body-wrapper">
          <div className="content-wrapper">
              {/* <h1 className="mainTitle">Speaker Biometric System</h1> */}
              <Tabs>
                <div label="Verifikasi" styleBorder={styleCursor}>
                  <p className="description">
                    Sistem ini melakukan verifikasi pemilik suara berdasarkan unggahan atau rekaman langsung oleh pengguna. Rekaman pertama digunakan untuk mendaftarkan <i>voiceprint</i>. Rekaman selanjutnya digunakan sebagai verifikasi. Silahkan melakukan rekaman dan pastikan mikrofonmu dalam keadaan aktif. Selamat mencoba...
                  </p>
                  <Verification />
                </div>
                <div label="Identifikasi" styleBorder={styleCursor}>
                  <p className="description">
                    Silakan ucapkan kata-kata dalam Bahasa Indonesia dan pastikan mikrofonmu dalam keadaan aktif atau dengan mengupload file audio. Selamat mencoba...
                  </p>
                  <Identification />
                </div>
              </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default SpeakerBiometric;
