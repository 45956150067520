import React, { Component } from "react";
import "../css/style.css";
import { Button } from "react-bootstrap";
import AddVoicesModal from "./AddVoicesModal";

class Verification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputName: "",
      inputFilename: "",
      namaFile: "",
      files: [],
      items_record: [],
      isLoading: false,
      isRecording: false,
      buttonClick: 0,
      show: false,
      showModalVerify: false,
      result_is_verified: "",
      gender: "",
      speakers_list: [],
      nameSpeaker: "",
      genderSpeaker: "",
      categoryModal: "",
      tooltipVerify: "",
    };

    this.getResultFromModal = this.getResultFromModal.bind(this)

    this.getNumberSpeaker = this.getNumberSpeaker.bind(this)
  }

  componentDidMount() {
    fetch('/v1/sid/speakers/get',
      {
        method: 'GET',
        headers: {
          'access-control-allow-origin': '*',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      .then(res => res.json())
      .then(
        (result) => {
            console.log("get speakers", result, result['speakers'])
            this.setState({
                speakers_list: result['speakers'],
            });
        },
      );
  }

//   onPreviewDrop = (files) => {
//     const inputFilename = document.getElementById('inputFilename')
//     console.log("input filename", inputFilename, files[0].preview)
//     inputFilename.value = files[0].preview
//     console.log("input filename", inputFilename)
//     this.setState({
//       files: this.state.files.concat(files),
//       inputFilename: files[0].preview
//     });

//   }

//   handleInputChange = (e) => {
//     e.preventDefault();
//     console.log('Tes', e, e.target.name, e.target.value, this.state.inputFilename)
//     let name = e.target.name;
//     let value = e.target.value;

//     this.setState({
//       [name]: value,
//       inputFilename: this.state.inputFilename
//     })
//   };

//   handleFormSubmit = (e) => {
//     // e.preventDefault();

//     let items_record = [...this.state.items_record];

//     items_record.push({
//       inputName: this.state.inputName,
//       inputFilename: this.state.inputFilename,
//     });
//     console.log('AYAYA', items_record, this.state.inputName, this.state.inputFilename)
//     this.setState({
//       items_record,
//       inputName: '',
//       inputFilename: ''
//     });
//   }

  getResultVerification() {
    const containerResult = document.getElementsByClassName('container-update-result')
    console.log("NANA", containerResult[0])
    containerResult[0].style.display = "block"
  }

  getResultFromModal(status, message) {
    console.log("update table", status, message)
    if (status === 200) {
        window.location.reload()
    }
  }

  deleteSpeaker(name) {
    console.log("to delete: ", name)
    const strDeleteApi = '/v1/sid/speakers/'+name+'/delete';
    console.log('URL deleted', strDeleteApi)
    fetch(strDeleteApi,
    {
        method: 'DELETE',
        headers: {
            'x-api-key': 'ProsaCerdas@bandung10',
        },
    })
    .then(res => res.json())
    .then(
        (result) => {
            console.log("RES", result)
            alert(result['message'])
            window.location.reload()
        },
    );
  }

  getNumberSpeaker(item) {
    return parseInt(item)+1
  }
  
  render() {
    const speakers_list = this.state.speakers_list;
    return (
        <div>
            <div style={{marginTop: '3rem'}}>
                <AddVoicesModal 
                    modal={this.state.show}
                    resultModal={this.getResultFromModal}
                    nameSpeaker={this.state.nameSpeaker}
                    genderSpeaker={this.state.genderSpeaker}
                    categoryModal={this.state.categoryModal}
                />
                <Button bsStyle="primary" onClick={() => this.setState({ show: true, categoryModal: "new_speaker"})}>
                    <i className="fa fa-plus-circle" aria-hidden="true" style={{marginRight: '1rem'}}></i>
                    Tambah Speaker Baru
                </Button>
            </div>
            <div style={{ marginTop: '4rem' }}>
                <table id="table-verification">
                    <tbody>
                        <tr>
                            <th className="small-column">Nomor</th>
                            <th>Nama</th>
                            <th>Pengaturan</th>
                        </tr>
                        {Object.keys(speakers_list).map(item => (
                            <tr key={speakers_list[item].name}>
                                <td className="small-column">
                                    {this.getNumberSpeaker(item)}
                                </td>
                                <td>
                                    {speakers_list[item].name}
                                </td>
                                <td>
                                    <i title="Verifikasi" onClick={() => this.setState({ show: true, nameSpeaker: speakers_list[item].name, genderSpeaker: speakers_list[item].gender, categoryModal: "verify" })} className="fa fa-check-square-o icon-configuration-verify c-icon-verify" aria-hidden="true"></i>
                                    <i title="Update" onClick={() => this.setState({ show: true, nameSpeaker: speakers_list[item].name, genderSpeaker: speakers_list[item].gender, categoryModal: "update" })} className="fa fa-pencil-square-o icon-configuration-verify c-icon-update" aria-hidden="true"></i>
                                    <i title="Hapus" onClick={this.deleteSpeaker.bind(this, speakers_list[item].name)} className="fa fa-trash icon-configuration-verify" aria-hidden="true"></i>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
                
    );
  }
}

export default Verification;
