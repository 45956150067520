import React, { Component } from "react";
import Header from "../components/Header";
import "../css/style.css";
import Synthesize from "../components/js/Synthesize";
import Footer from "../components/Footer";
import { FormGroup, FormControl, Button, Row } from "react-bootstrap";
// import { FormGroup, FormControl, Button, ControlLabel, Row } from "react-bootstrap";
import Sound from "react-sound";
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import LoadingGif from "../assets/loading.gif";

class TextToSpeech extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isError: false,
      dataTransfer: false,
      idxSynth: -1,
      gender: "male",
      urlSynth: "",
      execTime: 0,
      soundPlay: Sound.status.STOPPED,
      model: "atmadja",
      model_properties: {},
      audioSrc: null,
      tempoAudio: 1,
      pitchAudio: 0,
      textInput: ""
    };

    this.getProcessingStatus = this.getProcessingStatus.bind(this);
    this.handleSongFinishedPlaying = this.handleSongFinishedPlaying.bind(this);
    this.handleErrorPlaying = this.handleErrorPlaying.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handleChangeTempo = this.handleChangeTempo.bind(this);
    this.sleep = this.sleep.bind(this);

    var cfgSynthesize = {
      onResult: this.updateAudio,
      onOpen: this.onWSOpen,
      onError: this.errorMsg
    };

    this.synthesize = new Synthesize(cfgSynthesize);

    this.arrAudioName = [];
    this.textLength = 0;
  }

  getProcessingStatus(value) {
    // console.log("tts-> Processing", value);
    if (this.state.isLoading !== value) {
      this.setState({ isLoading: value });
    }
  }

  onWSOpen = async val => {
    // console.log("ON OPENN ", val);
  };

  errorMsg = async msg => {
    // console.log("ERROR ", msg.data);
    this.setState({ dataTransfer: false });
  };

  synthesizeText(Sound) {
    this.setState({ idxSynth: -1 });
    this.setState({ isLoading: true });
    this.arrAudioName = [];
    var text = this.state.textInput;

    var textArr = [];

    this.textLength = textArr.length;
    var that = this;
    var t1 = new Date().getTime();
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        // console.log("this response: ", this.responseText)
        // var resp = JSON.parse(this.responseText);
        // that.arrAudioName[parseInt(resp.idx, 10)] = resp.name;
        // console.log(that.arrAudioName);

        var blob = new Blob([xhttp.response], {type: 'audio/ogg'});
        var objectUrl = URL.createObjectURL(blob);

        var audio = document.getElementById('audio_play_blob') || new Audio();
        audio.src = objectUrl;
        
        audio.onload = function(evt) {
          URL.revokeObjectURL(objectUrl);
        };

        audio.play();

        // if (that.state.soundPlay === Sound.status.STOPPED) {
        //   if (that.arrAudioName[that.state.idxSynth + 1] !== undefined) {
            that.setState({ isLoading: false });
            that.setState({ idxSynth: that.state.idxSynth + 1 });
            that.setState({ soundPlay: Sound.status.PLAYING });
            if (that.state.idxSynth === 0) {
              var t2= new Date().getTime();
              that.setState({ execTime: t2 - t1});
            }
        //   }
        // }
      }
      if (this.readyState === 4 && this.status !== 200) {
        console.log(this.status);
      }
    };

    var modelTTS = {
      "atmadja" : "/v1/tts/atmadja/decode",
      "trisa" : "/v1/tts/trisa/decode",
      "gracia": "/v1/tts/gracia/decode"
    };

    var theUrl = modelTTS[this.state.model];
    console.log("the URL", theUrl)
    xhttp.open(
      "POST",
      theUrl,
      true
    );
    xhttp.setRequestHeader("x-api-key", "ProsaCerdas@bandung10");
    console.log("xhttp: ", xhttp);
    console.log("text ", text);
    xhttp.responseType = 'blob';
    console.log("Tempo Audio: ", this.state.tempoAudio);
    console.log("Pitch Audio: ", this.pitchAudio*10);
    var strPitchAudio = this.pitchAudio*10;
    var data = JSON.stringify({
      "text": text,
      "pitch": strPitchAudio.toString(),
      "tempo": this.state.tempoAudio
    });
    console.log("data: ", data)
    xhttp.send(data);
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  handleSongFinishedPlaying() {
    if (this.state.idxSynth === this.textLength - 1) {
      this.setState({ soundPlay: Sound.status.STOPPED });
      this.setState({ isLoading: false });
      this.setState({ idxSynth: 0 });
    } else {
      if (this.arrAudioName[this.state.idxSynth + 1] !== undefined) {
        this.setState({ idxSynth: this.state.idxSynth + 1 });
        this.setState({ soundPlay: Sound.status.PLAYING });
      } else {
        this.setState({ soundPlay: Sound.status.STOPPED });
        this.setState({ isLoading: true });
      }
    }
  }

  playSound() {
    this.setState({ soundPlay: Sound.status.STOPPED });
    this.setState({ idxSynth: 0 });
    this.setState({ soundPlay: Sound.status.PLAYING });
  }

  handleErrorPlaying(val) {
    this.setState({ soundPlay: Sound.status.STOPPED });
    this.setState({ soundPlay: Sound.status.PLAYING });
  }

  handleChangeGender(val) {
    console.log("model: ", val.target.value)
    this.setState({ model: val.target.value });
  }

  handleChangeTempo(val) {
    this.setState({ tempoAudio: val.target.value });
  }

  componentDidMount() {
    fetch('/v1/tts/index',
      {
        method: 'GET',
        headers: {
          'access-control-allow-origin': '*',
          'Content-type': 'application/json; charset=UTF-8',
          'x-api-key': 'ProsaCerdas@bandung10',
        },
      })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            model_properties: result,
          });
        },
      );
  }

  getAudioSrc() {
    const play_button = document.getElementById('audio_play_blob');
    if(play_button != null){
      play_button.play()
    }
  }

  saveAudio() {
    const play_button = document.getElementById('audio_play_blob');
    if(play_button != null){
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = play_button.src;
      a.download = "audio.wav";
      a.click();
      document.body.removeChild(a);
    }
  }

  handleChangePitch(val) {
    this.pitchAudio = val;
  }

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
        textInput: e.target.value,
    })
  };

  clearTextArea = (e) => {
    console.log('clear', this.state.textInput)
    this.setState({
      textInput: "",
      idxSynth: -1,
      execTime: 0,
    })
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const model_properties = this.state.model_properties;
    return (
      <div>
        {this.state.isError}
        <Header name={"texttospeech"} />
        <div className="body-wrapper">
          <div>
            <div className="tts-wrapper">
              <div className="content-wrapper" style={{ width: "100%" }}>
                {/* <h2 className="subTitle">Demo</h2> */}
                {/* <h1 className="mainTitle">Text-to-Speech System</h1> */}
                <p className="description">
                  Halaman ini untuk menguji suara yang dihasilkan dari teks
                  masukan. Silakan masukkan teks pada kolom berikut dan tekan
                  tombol Sintesis.
                </p>
                <Row style={{marginTop: "5px", display: "flex", marginLeft: "0rem", marginRight: "0rem"}}>
                  <div>
                    <div className="model-title">
                      Gender
                    </div>
                    <FormGroup controlId="select-gender" className="form-model-options">
                      <FormControl
                        className="form-control-model"
                        componentClass="select"
                        placeholder="Pilih salah satu"
                        value={this.state.model}
                        onChange = {this.handleChangeGender}>
                        {Object.keys(model_properties).map(item => (
                          <option value={item} key={item}>{model_properties[item].gender}</option>
                        ))}
                      </FormControl>
                    </FormGroup>
                  </div>
                  <div>
                    <div className="model-title">
                      Gaya Bicara
                    </div>
                    {this.state.model === "atmadja" ?
                      <FormGroup controlId="select-gender" className="form-model-options">
                        <FormControl
                          className="form-control-model"
                          componentClass="select"
                          placeholder="Pilih salah satu">
                          <option value="formal">Formal</option>
                        </FormControl>
                      </FormGroup>
                    :
                      <FormGroup controlId="select-gender" className="form-model-options">
                        <FormControl
                          className="form-control-model"
                          componentClass="select"
                          placeholder="Pilih salah satu">
                          <option value="ngobrol">Ngobrol</option>
                        </FormControl>
                      </FormGroup>
                    }
                    
                  </div>
                  <div>
                    <div className="model-title">
                      Kecepatan
                    </div>
                    <FormGroup controlId="select-gender" className="form-model-options">
                      <FormControl
                        className="form-control-model"
                        componentClass="select"
                        placeholder="Pilih salah satu"
                        value={this.state.tempoAudio}
                        onChange = {this.handleChangeTempo}>
                        <option value="0.25">0.25</option>
                        <option value="0.5">0.5</option>
                        <option value="0.75">0.75</option>
                        <option value="1">Normal</option>
                        <option value="1.25">1.25</option>
                        <option value="1.5">1.5</option>
                        <option value="1.75">1.75</option>
                        <option value="2">2</option>
                      </FormControl>
                    </FormGroup>
                  </div>
                  <div>
                    <div className="model-title">
                      Tinggi Nada
                    </div>
                    <StyledSlider
                        // renderTrack={Track}
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        min={-100}
                        max={100}
                        defaultValue={0}
                        renderThumb={(props, state) => 
                          <div
                            {...props}
                          >
                            {this.handleChangePitch(state.valueNow)}
                            {state.valueNow}
                          </div>}
                    />
                  </div>
                </Row>
                <FormGroup>
                  <FormControl
                    componentClass="textarea"
                    type="text"
                    name="text"
                    id="text-input"
                    rows={10}
                    placeholder="Teks masukan"
                    value={this.state.textInput}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </FormGroup>
                {this.state.execTime !== 0 && this.state.textInput && (
                  <p>Execution Time: {this.state.execTime} milliseconds</p>
                )}
                <div style={{marginBottom: "80px", marginTop: "30px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                {this.state.isLoading || !this.state.textInput ? (
                  <Button
                    bsStyle="primary"
                    style={{marginLeft:"0px", paddingLeft: "16px", paddingRight: "16px"}}
                    // bsStyle="primary"
                    disabled
                  >
                    Sintesis
                  </Button>
                ) : (
                  <Button
                    bsStyle="primary"
                    style={{marginLeft:"0px", paddingLeft: "16px", paddingRight: "16px"}}
                    // bsStyle="primary"
                    onClick={this.synthesizeText.bind(this, Sound)}
                  >
                    Sintesis
                  </Button>
                )}
                {this.state.idxSynth !== -1 && this.state.textInput ? (
                  <Button
                    bsStyle="primary"
                    style={{marginLeft:"10px", paddingLeft: "16px", paddingRight: "16px"}}
                    // bsStyle="primary"
                    onClick={this.saveAudio}
                  >
                    Simpan
                  </Button>
                ) : (
                  <Button
                    bsStyle="primary"
                    style={{marginLeft:"10px", paddingLeft: "16px", paddingRight: "16px"}}
                    // bsStyle="primary"
                    disabled
                  >
                    Simpan
                  </Button>
                )}
                {this.state.idxSynth !== -1 && this.state.textInput && (
                  <Button
                    bsStyle="primary"
                    style={{marginLeft:"10px", paddingLeft: "16px", paddingRight: "16px"}}
                    // bsStyle="primary"
                    onClick={this.getAudioSrc}
                  >
                    Replay
                  </Button>
                )}
                {this.state.isLoading ? (
                  <span>
                    <img
                      src={LoadingGif}
                      alt="loading"
                      className="loading-gif-tts"
                    />
                  </span>
                ) : (
                  ""
                )}

                {this.state.isLoading || !this.state.textInput ? (
                  <Button
                    bsStyle="primary"
                    style={{position:"absolute", right: "8%"}}
                    // bsStyle="primary"
                    disabled
                  >
                    Hapus Teks
                  </Button>
                ) : (
                  <Button
                    bsStyle="primary"
                    style={{position:"absolute", right: "8%", backgroundColor: "#8B0000", borderColor: "#8B0000"}}
                    // bsStyle="primary"
                    onClick={this.clearTextArea.bind(this)}
                  >
                    Hapus Teks
                  </Button>
                )}
                </div>
                  
                {/* <div className="play_container"> */}
                <audio id="audio_play_blob" src={this.state.audioSrc} type="audio/wav" ref="audio_tag" ></audio>
                    {/* <button className="button-circle button-style" onClick={this.getAudioSrc} id="play_button">
                      <span className="button-circle-font button-font-play">Putar</span>
                    </button> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TextToSpeech;

const StyledSlider = styled(ReactSlider)`
    width: 20rem;
    height: 25px;
    margin-top: 0.9rem;
`;
