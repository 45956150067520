import React, { Component } from "react";
import Header from "../components/Header";
import Uploader from "../components/Uploader";
// import AudioRecorder from "../components/AudioRecorder";
// import LoadingGif from "../assets/loading.gif";
// import Translation from "../components/Translation";
import "../css/style.css";
// import Footer from "../components/Footer";
import Decode from "../components/js/Decode";
// import {tes} from "../components/js/ParseMultipartData";
import { FormGroup, FormControl, Row, Col, Button } from "react-bootstrap";
import TranscriptList from '../components/TranscriptList';
// import Form from './Form';
import { Howl } from 'howler';
import RecordingAudio from "../components/RecordingAudio";
import { saveAs } from 'file-saver';
var JSZip = require("jszip");
// tes()

class SpeechToText extends Component {
  constructor() {
    super();
    this.state = {
      lastTextReceivedTime: Date.now(),
      text: "",
      audioSrc: "",
      filename: "",
      recordItems: [],
      isLoading: false,
      isRecording: false,
      isUploadFile: false,
      model: "bca",
      model_properties: {},
      files: "",
      isTranscribing: false, // 
      playingStatus: 0, // 0 stoped, 1 playing, 2 paused
    };

    this.updateTranscribing = this.updateTranscribing.bind(this);

    var cfgDecode = {
      onResult: this.updateTranscribing,
    };
    this.decode = new Decode(cfgDecode);

    this.handleChangeModel = this.handleChangeModel.bind(this);

    this.setProcessingStatus = this.setProcessingStatus.bind(this);
    this.setUploadFileStatus = this.setUploadFileStatus.bind(this);
    this.playRecord = this.playRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.setAudioPlayStatus = this.setAudioPlayStatus.bind(this);
    this.transcribeAudio = this.transcribeAudio.bind(this)
    this.saveTranscriptResult = this.saveTranscriptResult.bind(this)
    this.setResultBlob = this.setResultBlob.bind(this)
    this.playPauseAudio = this.playPauseAudio.bind(this)
    this.stopAudio = this.stopAudio.bind(this)
    this.setAudio = this.setAudio.bind(this)
    this.getRecommendedSampleRate = this.getRecommendedSampleRate.bind(this)
  }

  componentDidMount() {
    fetch('/v1/asr/index',
      {
        method: 'GET',
        headers: {
          'access-control-allow-origin': '*',
          'Content-type': 'application/json; charset=UTF-8',
          'x-api-key': 'ProsaCerdas@bandung10',
        },
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log('result', result)
          let filterdResult = {}
          filterdResult['mk-ky'] = result['mk-ky']
          filterdResult['talkshow'] = result['talkshow']

          this.setState({
            model:'mk-ky',
            model_properties: filterdResult,
          });
        },
      );
  }

  transcribeAudio() {
    this.setState({
      isTranscribing: true,
      recordItems: [],
    })
    console.log('this', this.decode)
    let uploadedData = this.state.files[0]
    this.decode.PostBlob(uploadedData, this.state.model, this.state.files[0].name)
  }

  saveTranscriptResult() {
    let items = [];
    let text = ''
    for (let i = 0; i < this.state.recordItems.length; i++) {
      let recordItem = this.state.recordItems[i]
      text += `${recordItem.text}\n`
    }

    items.push({
      text: text,
      audioSrc: this.state.files[0],
      filename: 'Transcription_result'
    })

    console.log('saving')
    var zip = new JSZip();

    for (var i = 0; i < items.length; i++) {
      zip.file('transcript_' + (i + 1) + '.txt', items[i]['text'])
      zip.file('audio_' + (i + 1) + '.wav', items[i]['audioSrc'])
    }

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        saveAs(content, items[0]['filename']);
      });
  }

  updateTranscribing = (data, blobUrl, namaFile) => {
    console.log("update transcribing date result", data);
    console.log("update transcribing audio result", blobUrl);
    console.log("update transcribing filename result", namaFile);
    this.setState({
      recordItems: data[0],
      audioSrc: blobUrl,
      filename: namaFile,
      isLoading: false,
      isTranscribing: false,
    });
  }

  playPauseAudio(){
    if(this.state.playingStatus !== 1){
      this.state.audio.play()
    } else {
      this.state.audio.pause()
    }
  }

  stopAudio(){
    this.state.audio.stop()
  }
  
  setProcessingStatus(value) {
    console.log("s2t -> Processing", value);
    if (this.state.isLoading !== value) {
      this.setState({ isLoading: value });
    }
  }

  setUploadFileStatus() {
    this.setState({ isUploadFile: true });
  }

  setAudioPlayStatus(value) {
    console.log("audio play status", value);
  }

  setAudio(blobUrl){
    let that = this
    console.log('set audio')
    let audio = new Howl({
      src: [blobUrl.preview],
      format: ['wav'],
      onplay : function (){
        that.setState({
          playingStatus: 1,
        })
      },
      onpause : function (){
        that.setState({
          playingStatus: 2,
        })
      },
      onstop : function (){
        that.setState({
          playingStatus: 0,
        })
      },
      onend : function (){
        that.setState({
          playingStatus: 0,
        })
      }
    })
    this.setState({
      audio: audio
    })
  }

  setFiles(files) {
    this.setState({
      recordItems : []
    })
    console.log('accepted files', files)
    this.setAudio(files[0])
    this.setState({
      files: files,
    })
  }

  setResultBlob(blob) {
    this.setState({
      recordItems : []
    })
    console.log("verify blob ", blob)

    let url = URL.createObjectURL(blob);

    let file = new File([blob], 'audio.webm')
    file.preview = url
    let files = []
    files.push(file)

    this.setState({
      files: files,
    });

    this.setAudio(files[0])

    this.setState({
      isRecording: false,
    })
    console.log(this.state.files)
  }

  handleChangeModel(e) {
    console.log("model: ", e.target.value);
    this.setState({ model: e.target.value });
  }

  setRecording() {
    this.setState({
      isRecording: true,
      recordItems: [],
      files: ""
    })
  }

  deleteRecord(key) {

    const recordItems = [...this.state.recordItems]
    console.log("items record before delete ", recordItems)

    // removes a single instance, at this index of array
    recordItems.splice(key, 1)

    console.log("items record after delete ", recordItems)

    this.setState({
      recordItems: recordItems,
    })

  }

  playRecord(blobUrl) {
    console.log("play blob url: ", blobUrl)
    var audio = new Audio();
    audio.src = blobUrl;
    audio.play();
  }

  getRecommendedSampleRate() {
    let sampleRateMap = {
      'bca': 8,
      'telkom': 8,
      'talkshow': 16,
      'mk-ky':16,
      'gabungan-hmm-001': 16,
      'kemenlu': 16,
    }
    return sampleRateMap[this.state.model]
  }

  render() {
    const model_properties = this.state.model_properties;
    return (
      <div>
        <Header name={"speechtotext"} />
        <div className="body-wrapper">
          <div className="content-wrapper">
            {/* <h1 className="mainTitle">Speech to Text System</h1> */}
            <p className="description">
              Sistem ini dapat mengubah ucapan ke dalam bentuk teks. Silakan ucapkan kata-kata dalam Bahasa Indonesia dan pastikan mikrofonmu dalam keadaan aktif. Selamat mencoba...
            </p>
            <Row style={{ marginTop: "5px" }}>
              <Col lg={12} sm={12} xs={12}>
                <div className="model-title">
                  Domain
                </div>
                <FormGroup controlId="select-model" className="form-model-options">
                  <FormControl
                    className="form-control-model"
                    componentClass="select"
                    placeholder="Pilih salah satu"
                    value={this.state.model}
                    onChange={this.handleChangeModel}
                  >

                    {Object.keys(model_properties).map(item => (
                      <option value={item} key={item}>{model_properties[item].domain}</option>
                    ))}

                  </FormControl>
                </FormGroup>
                <div className="uploadInline">
                  <div onClick={this.setRecording.bind(this)}>
                    <RecordingAudio
                      category="asr"
                      resultBlob={this.setResultBlob}
                    />
                  </div>
                </div>
                <Uploader
                  isRecording={this.state.isRecording}
                  model={this.state.model}
                  processingStatus={this.setProcessingStatus}
                  uploadFileStatus={this.setUploadFileStatus}
                  acceptedFiles={this.setFiles}
                />
              </Col>
              <div style={{ marginLeft: '15px'}}>
                <p>
                  Rekomendasi <i>sample rate</i> audio: <b>{this.getRecommendedSampleRate()} kHz</b>
                </p>
              </div>
            </Row>
          </div>

          {this.state.files ? (
            <div id="Table" style={{ marginTop: '2rem', width: '80%' }}>
              <div className="model-title">
                Audio
              </div>
              <table>
                <tbody>
                  <tr>
                    <td alt="Preview" key={this.state.files[0].name}>
                      File: {this.state.files[0].name}
                    </td>
                    <td>
                      <audio className="preview-audio" src={this.state.files[0].preview} controls style={{ height: '40px', width: '250px', marginLeft: '36px', marginTop: '6px' }}></audio>
                    </td>
                    <td>
                      <Button
                        bsStyle="primary"
                        style={{ marginLeft: "24px", paddingLeft: "16px", paddingRight: "16px", borderRadius: '20px' }}
                        onClick={this.transcribeAudio}
                      >
                        Transkrip
                        {
                          this.state.isTranscribing ? <i className="fa fa-circle-o-notch fa-spin" style={{color: 'white', marginLeft:'0.6rem' }} ></i> : ""
                        }
                      </Button>
                      <Button
                        bsStyle="primary"
                        style={{ marginLeft: "24px", paddingLeft: "16px", paddingRight: "16px", borderRadius: '20px' }}
                        onClick={this.saveTranscriptResult}
                      >
                        Simpan
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
              ""
            )}
          <TranscriptList
            recordItems={this.state.recordItems}
            playPauseAudio={this.playPauseAudio}
            stopAudio={this.stopAudio}
            playingStatus={this.state.playingStatus}
            saveTranscript={this.saveTranscriptResult}
          />
          {/* {this.state.isTranscribing ? (
            <div className="loading-wrapper">
              <img src={LoadingGif} alt="loading" className="loading-gif" />
            </div>
          ) : (
              ""
            )} */}
            <div className="foot-notes" style={{marginTop:'4rem'}}>
              <div className="model-title">
                Catatan:
              </div>
              <ul>
                <li>
                  Akurasi Domain saat ini : Pengadilan <b>95%</b>, Talkshow <b>75%</b>
                </li>
                <li>
                  Audio yang diupload tidak diperkenankan melebihi <b>1MB</b>
                </li>
                <li>
                  Durasi audio maksimal <b>30 detik</b> untuk metode perekaman
                </li>
                <li>
                  Masa aktif domain hanya <b>1 minggu</b><br/>Silakan menghubungi tim Bisnis
                  Prosa untuk kebutuhan lebih lanjut di &nbsp;
                  <a href="mailto:business@prosa.ai" target="_blank_">business@prosa.ai</a>
                </li>
              </ul>
            </div>
          <div
            ref={el => {
              this.messagesEnd = el;
            }}
          />
        </div>
      </div>
    );
  }
}

export default SpeechToText;
