import React from 'react';
import RecordRTC from 'recordrtc';
import { MonoAudioRecorder } from "recordrtc";

class RecordingAudio extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        recordAudio: null,
        isRecording: false,
        blob: ""
    };

    this.requestUserMedia = this.requestUserMedia.bind(this);
    this.startRecord = this.startRecord.bind(this);
    this.stopRecord = this.stopRecord.bind(this);
    this.getUserMedia = this.getUserMedia.bind(this);
  }

  requestUserMedia() {
    this.getUserMedia(stream => {
      this.setState({ src: window.URL.createObjectURL(stream) });
    });
  }

  startRecord() {
    console.log("start recording")
    this.setState({
        isRecording: true
    })
    this.getUserMedia(stream => {
      this.setState({
        recordAudio : RecordRTC(stream, { type: 'audio', mimeType: 'audio/wav', recorderType: MonoAudioRecorder, desiredSampRate: 16000, }),
      })
      this.state.recordAudio.startRecording();
      let recordAudio = this.state.recordAudio
      recordAudio.microphone = stream
      this.setState({
        recordAudio: recordAudio
      })
    });
  }

  stopRecord() {
    this.setState({
        isRecording: false
    })
    this.state.recordAudio.microphone.stop();
    this.state.recordAudio.stopRecording(() => {
        let blob = this.state.recordAudio.getBlob();
        // console.log("blob, ",blob)
        // let url = URL.createObjectURL(blob);
        // console.log("url", url);
        this.props.resultBlob(blob)
        // this.state.recordAudio.getDataURL((dataURL)=>{this.props.resultBlob(blob, dataURL)});
    });
  }

  getUserMedia(callback) {
    navigator.getUserMedia({ audio: true }, callback, error => alert(JSON.stringify(error)));
  }

  render() {
    return (
        <div>
          {this.props.category === "enroll_verif" ?
              this.state.isRecording?
                <div style={{display: 'flex'}}>
                  <button onClick={this.startRecord} disabled id="edit-start-online-enroll" type="button" className="btn btn-primary btn-rounded" style={{marginRight: '10px', borderRadius: '50px'}}>Mulai Rekam</button>
                  <button onClick={this.stopRecord} id="edit-stop-online-enroll" type="button" className="btn btn-primary btn-rounded" style={{borderRadius: '50px'}}>Berhenti</button>
                </div>
              :
                <div style={{display: 'flex'}}>
                  <button onClick={this.startRecord} id="edit-start-online-enroll" type="button" className="btn btn-primary btn-rounded" style={{marginRight: '10px', borderRadius: '50px'}}>Mulai Rekam</button>
                  <button onClick={this.stopRecord} disabled id="edit-stop-online-enroll" type="button" className="btn btn-primary btn-rounded" style={{borderRadius: '50px'}}>Berhenti</button>
                </div>
          :
            this.state.isRecording?
                <div className="button-rec-hover" style={{marginRight: "25px"}}>
                    <button onClick={this.stopRecord} className="button-circle button-style">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path fill="#ffffff" d="M6 6h12v12H6z"/>
                        </svg>
                        <span className="button-circle-font button-font">Berhenti</span>
                    </button>
                </div>
            :
                <div className="button-rec-hover" style={{marginRight: "25px"}}>
                    <button onClick={this.startRecord} className="button-circle button-style">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                            <path fill="#ffffff" d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                        <span className="button-circle-font button-font">Rekam</span>
                    </button>
                </div>
          }
        {/* <button onClick={this.startRecord}>Start Record</button>
        <button onClick={this.stopRecord}>Stop Record</button> */}
      </div>
    )
  }
}

export default RecordingAudio;