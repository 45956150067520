import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { FormGroup, FormControl, Button, Row, Col } from "react-bootstrap";
import RecordingAudio from "../components/RecordingAudio";
import "../css/style.css";
import ReactDropzone from "react-dropzone";
// import LoadingGif from "../assets/loading.gif";


class AddVoicesModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            isRecording: false,
            show: props.modal,
            inputName: "",
            inputFileName: "",
            namaFile: "",
            files: "",
            fileAudio: "",
            items_record: [],
            gender: "male",
            messageEnroll: "",
            result_is_verified: "",
            showRes: false,
            selectedOption: "upload"
        };
        
        this.getRecordingStatus = this.getRecordingStatus.bind(this);
        this.getTranscriptionStatus = this.getTranscriptionStatus.bind(this);
        this.getAudioPlayStatus = this.getAudioPlayStatus.bind(this);
        this.getAudioStatus = this.getAudioStatus.bind(this);

        this.handleChangeGender = this.handleChangeGender.bind(this);
        this.getResultBlob = this.getResultBlob.bind(this);
    }

    getRecordingStatus(value) {
        console.log("s2t -> recording", value);
        if (this.state.isRecording !== value) {
          this.setState({ isRecording: value }, value => {
            if (value) {
              this.setState({
                transcription: {
                  status: false,
                }
              });
            }
          });
        }
    }

    getTranscriptionStatus(value) {
        console.log("s2t -> Transcription", value);
        var currentTime = Date.now() / 1000;
        if (this.state.text !== "" && (currentTime - this.state.lastTextReceivedTime > 4 || this.state.isUploadFile)) {
          this.setState({
            isLoading: false,
            // text: this.state.text + "\n\n" + value,
            text: this.state.text,
            lastTextReceivedTime: currentTime,
            isUploadFile: false
          });
        } else {
          this.setState({
            isLoading: false,
            // text: this.state.text + " " + value,
            text: this.state.text,
            lastTextReceivedTime: currentTime
          });
        }
    }

    getAudioPlayStatus(value) {
        console.log("audio play status", value);
    }

    getAudioStatus(value) {
        console.log("audio status", value)
        this.setState({
          audioSrc: value
        })
    }

    componentWillReceiveProps(nextProps){
    	if(this.state.show!==nextProps.modal){
      	    this.setState({show: nextProps.modal})
        }
    }

    handleInputChange = (e) => {
        e.preventDefault();
        // let name = e.target.name;
        // let value = e.target.value;
    
        this.setState({
            inputName: e.target.value,
            inputFileName: this.state.inputFileName
        })
    };

    onPreviewDrop = (accepted, rejected) => {
        console.log('file accepted ', accepted, accepted.length)
        if (accepted.length < 1) {
            alert("File Tidak Valid");
        } else {
            console.log("accepted ", accepted)

            console.log("input filename", accepted[0].preview)
            this.setState({
                fileAudio: accepted[0],
                files: accepted,
                inputFileName: accepted[0].preview,
                namaFile: accepted[0].name
            });
            
        }
    }

    handleFormSubmit = (e) => {
        console.log("form submitted", this.state.inputName, this.state.inputFileName)
    }

    submitModal() {
        console.log("submit modal", this.state.gender, this.state.inputName, this.state.inputFileName, this.state.namaFile)
        
        // console.log("form submitted", name, filename)
        // let items_record = [...this.state.items_record];
        // items_record.push({
        //     inputName: this.state.inputName,
        //     namaFile: this.state.namaFile,
        //     files: this.state.fileAudio,
        //     gender: this.state.gender
        // });
        // console.log("modal result", items_record)

        const fdEnroll = new FormData();
        fdEnroll.append('voice', this.state.fileAudio);
        fdEnroll.append('speaker_name', this.state.inputName);
        fdEnroll.append('gender', this.state.gender);
        
        fetch('/v1/sid/set_a/enroll',
        {
            method: 'POST',
            body: fdEnroll,
            headers: {
                'x-api-key': 'ProsaCerdas@bandung10',
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log("enroll result", result, result['status'])
                if (result['status'] === 200) {
                    alert(result['message'])
                    this.props.resultModal(200, result['message'])
                    this.setState({
                        messageEnroll: result['message']
                    })
                } else {
                    alert(result['message'])
                    this.props.resultModal(400, result['message'])
                    this.setState({
                        messageEnroll: result['message']
                    })
                }
                this.setState({ 
                    show: false,
                    inputName: "",
                    inputFileName: "",
                    namaFile: "",
                    files: "",
                    fileAudio: "",
                    gender: "male",
                    items_record: [],
                    messageEnroll: "",
                    selectedOption: "upload"
                });
            },
        );
    }

    verifySpeaker() {
        console.log("submit modal", this.props.genderSpeaker, this.props.nameSpeaker, this.state.fileAudio, this.state.inputFileName, this.state.namaFile)
        
        console.log("onclick button verify", this.props.nameSpeaker, this.state.fileAudio, this.props.genderSpeaker)
        const fdVerify = new FormData();
        fdVerify.append('voice', this.state.fileAudio);
        fdVerify.append('speaker_name', this.props.nameSpeaker);
        fdVerify.append('gender', this.props.genderSpeaker);
        
        fetch('/v1/sid/set_a/verify',
        {
            method: 'POST',
            body: fdVerify,
            headers: {
                'x-api-key': 'ProsaCerdas@bandung10',
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log("verify result", result)
                if (result['verified'] === true) {
                    this.setState({
                        result_is_verified: 'True',
                        showRes: true
                    })
                    // alert(result['verified'])
                    // this.props.resultModal(200, result['verified'])
                } else if (result['verified'] === false) {
                    this.setState({
                        result_is_verified: 'False',
                        showRes: true
                    })
                    // alert(result['verified'])
                    // this.props.resultModal(200, result['verified'])
                } else {
                    this.setState({
                        result_is_verified: "",
                    })
                    alert(result['message'])
                    // this.props.resultModal(400, result['message'])
                }

                this.setState({ 
                    showModalVerify: false,
                    inputName: "",
                    inputFileName: "",
                    namaFile: "",
                    files: "",
                    fileAudio: "",
                    selectedOption: "upload"
                });
            },
        );
    }

    handleChangeGender(val) {
        console.log("gender: ", val.target.value)
        this.setState({ gender: val.target.value });
    }

    getResultBlob(blob) {
        console.log("verify blob ", blob)

        let url = URL.createObjectURL(blob);
        console.log("url", url);

        this.setState({
            fileAudio: blob,
            files: blob,
            inputFileName: url,
            namaFile: "audio.webm"
        });

        this.setState({
            isRecording: false,
        })
    }

    recordingStatus() {
        this.setState({
            isRecording: true,
        })
    }

    handleOptionChange = (e) => {
        this.setState({
          selectedOption: e.target.value
        });
    };

    render(){
        let close = () => this.setState({ 
            show: false,
            inputName: "",
            inputFileName: "",
            namaFile: "",
            files: "",
            fileAudio: "",
            gender: "male",
            items_record: [],
            messageEnroll: "",
            selectedOption: "upload"
        });
        let closeRes = () => this.setState({ 
            show: false,
            inputName: "",
            inputFileName: "",
            namaFile: "",
            files: "",
            fileAudio: "",
            gender: "male",
            items_record: [],
            messageEnroll: "",
            selectedOption: "upload"
        });
        const gender_options = {
            "male": {
                "gender": "Pria"
            },
            "female": {
                "gender": "Wanita"
            }
        }
        return (
            <div className="modal-container">
                {this.props.categoryModal==="new_speaker"?
                    <Modal
                        show={this.state.show}
                        onHide={close}
                        container={this}
                        aria-labelledby="contained-modal-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title">Tambah Speaker Baru</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={this.handleFormSubmit} className="form-group">
                                <label style={{fontWeight: '700'}}>Nama</label>
                                <input id="inputName" type='text' className="form-control" name="inputName" onChange={this.handleInputChange} />
                                
                            </form>
                            <div>
                                <label style={{fontWeight: '700'}}>Gender</label>
                                {/* {this.state.isLoading ? ( */}
                                    {/* <span style={{ position: 'absolute', top: '45%', left: '46%'}}>
                                            <img
                                                src={LoadingGif}
                                                alt="loading"
                                                style={{maxWidth: '45px', maxHeight: '45px'}}
                                            />
                                        </span> */}
                                    {/* ) : ( */}
                                        {/* "" */}
                                    {/* )} */}
                                <FormGroup controlId="select-gender">
                                    <FormControl
                                        className="form-control-model"
                                        componentClass="select"
                                        placeholder="Pilih salah satu"
                                        value={this.state.gender}
                                        onChange = {this.handleChangeGender}
                                    >

                                        {Object.keys(gender_options).map(item => (
                                            <option value={item} key={item}>{gender_options[item].gender}</option>
                                        ))}

                                    </FormControl>
                                </FormGroup>
                            </div>
                            {/* <Row style={{ marginTop: '25px'}}>
                                {!this.state.fileAudio ?
                                    <div>
                                        <Col lg={5} sm={5} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                            {this.state.isRecording ?
                                                <div>
                                                    <ReactDropzone
                                                        multiple={false}
                                                        accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg"
                                                        onDrop={this.onPreviewDrop}
                                                        style={reactDropzoneStyle}
                                                        disabled
                                                    >
                                                    <div className="button-circle-disabled">
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24px" height="24px" viewBox="-0.5 -0.5 71 71"><defs/>
                                                        <g>
                                                            <path d="M 10 9.25 L 37.67 9.25 L 37.67 0 L 60 25 L 37.67 50 L 37.67 40.75 L 10 40.75 L 10 25 Z" fill="#ffffff" stroke="none" transform="rotate(270,35,25)" pointerEvents="none"/>
                                                            <rect x="0" y="60" width="70" height="10" fill="#ffffff" stroke="none" pointerEvents="none"/>
                                                        </g>
                                                        </svg>
                                                        <span className="button-font">Unggah Audio</span>
                                                    </div>
                                                    </ReactDropzone>
                                                </div>
                                            :
                                                <div>
                                                    <ReactDropzone
                                                        multiple={false}
                                                        accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg"
                                                        onDrop={this.onPreviewDrop}
                                                        style={reactDropzoneStyle}
                                                    >
                                                    <div className="button-circle button-style">
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24px" height="24px" viewBox="-0.5 -0.5 71 71"><defs/>
                                                        <g>
                                                            <path d="M 10 9.25 L 37.67 9.25 L 37.67 0 L 60 25 L 37.67 50 L 37.67 40.75 L 10 40.75 L 10 25 Z" fill="#3f88fc" stroke="none" transform="rotate(270,35,25)" pointerEvents="none"/>
                                                            <rect x="0" y="60" width="70" height="10" fill="#3f88fc" stroke="none" pointerEvents="none"/>
                                                        </g>
                                                        </svg>
                                                        <span className="button-circle-font button-font">Unggah Audio</span>
                                                    </div>
                                                    </ReactDropzone>
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={2} sm={2} xs={12} style={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
                                            <div>
                                                Atau
                                            </div>
                                        </Col>
                                        <Col lg={5} sm={5} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                            <div onClick={this.recordingStatus.bind(this)}>
                                                <RecordingAudio
                                                    category="verification"
                                                    resultBlob={this.getResultBlob}
                                                />
                                            </div>
                                        </Col>
                                    </div>
                                :""}
                            </Row> */}

                            <form>
                                <label>Contoh Audio</label>
                                <div className="radio" style={{marginTop: '2px'}}>
                                    <label>
                                        <input
                                            type="radio"
                                            value="upload"
                                            checked={this.state.selectedOption === 'upload'}
                                            onChange={this.handleOptionChange}
                                        />
                                        Unggah dari perangkat
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value="record"
                                            checked={this.state.selectedOption === 'record'}
                                            onChange={this.handleOptionChange}
                                        />
                                        Rekam
                                    </label>
                                </div>

                                {this.state.selectedOption === "upload" ?
                                    <div style={{marginTop: '5px'}} id="edit-upload-section" data-plugin="dropzone" data-previews-container="#file-previews" data-upload-preview-template="#uploadPreviewTemplate">
                                        <div className="fallback">
                                            {/* <span>File audio yang diizinkan: .wav, .mp3, .m4a, .webm, .weba</span> */}
                                            <ReactDropzone
                                                multiple={false}
                                                accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg, .m4a, .webm, .weba"
                                                onDrop={this.onPreviewDrop}
                                                style={reactDropzoneStyle}
                                            >
                                                <div style={{display: 'flex'}}>
                                                    <button id="edit-start-online-enroll" type="button" className="btn btn-primary btn-rounded" style={{marginRight: '10px', borderRadius: '50px'}}>Pilih File</button>
                                                </div>
                                            </ReactDropzone>
                                        </div>
                                    </div>
                                :
                                    <div style={{marginTop: '5px'}}>
                                        <div onClick={this.recordingStatus.bind(this)}>
                                            <RecordingAudio
                                                category="enroll_verif"
                                                resultBlob={this.getResultBlob}
                                            />
                                        </div>
                                    </div>
                                }
                                <div style={{marginTop: '12px'}}>
                                    {this.state.fileAudio?
                                        <div
                                            alt="Preview"
                                            key={this.state.namaFile}
                                        >
                                            File: {this.state.namaFile}
                                            <br/>
                                            
                                        </div>
                                    :""}
                                </div>
                                {this.state.inputFileName ?
                                    <audio id="edit-preview-audio" src={this.state.inputFileName} controls style={{marginTop: '10px'}}></audio>
                                :
                                    ""
                                }
                            </form>
                            
                        </Modal.Body>
                        <Modal.Footer>
                            { this.state.inputName && this.state.inputFileName ?
                                <div>
                                    <Button 
                                        bsStyle="primary"
                                        onClick={this.submitModal.bind(this)}
                                    >
                                        Tambahkan
                                    </Button>
                                    <Button onClick={close}>Tutup</Button>
                                </div>
                                :
                                <div>
                                    <Button bsStyle="primary" disabled>Tambahkan</Button>
                                    <Button onClick={close}>Tutup</Button>
                                </div>
                                
                            }
                        </Modal.Footer>
                    </Modal>
                :""}
                {this.props.categoryModal==="update"?
                    <Modal
                        show={this.state.show}
                        onHide={close}
                        container={this}
                        aria-labelledby="contained-modal-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title">Update Speaker {this.props.nameSpeaker}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={this.handleFormSubmit} className="form-group">
                                <label style={{fontWeight: '500'}}>Nama</label>
                                <input id="inputName" type='text' className="form-control" name="inputName" onChange={this.handleInputChange} />
                                
                            </form>
                            <div>
                                <label style={{fontWeight: '500'}}>Gender</label>
                                <FormGroup controlId="select-gender" className="options-tts">
                                    <FormControl
                                        className="form-control-model"
                                        componentClass="select"
                                        placeholder="Pilih salah satu"
                                        value={this.state.gender}
                                        onChange = {this.handleChangeGender}
                                    >

                                        {Object.keys(gender_options).map(item => (
                                            <option value={item} key={item}>Gender {gender_options[item].gender}</option>
                                        ))}

                                    </FormControl>
                                </FormGroup>
                            </div>
                            <form>
                                <label>Contoh Audio</label>
                                <div className="radio" style={{marginTop: '2px'}}>
                                    <label>
                                        <input
                                            type="radio"
                                            value="upload"
                                            checked={this.state.selectedOption === 'upload'}
                                            onChange={this.handleOptionChange}
                                        />
                                        Unggah dari perangkat
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value="record"
                                            checked={this.state.selectedOption === 'record'}
                                            onChange={this.handleOptionChange}
                                        />
                                        Rekam
                                    </label>
                                </div>

                                {this.state.selectedOption === "upload" ?
                                    <div style={{marginTop: '5px'}} id="edit-upload-section" data-plugin="dropzone" data-previews-container="#file-previews" data-upload-preview-template="#uploadPreviewTemplate">
                                        <div className="fallback">
                                            {/* <span>File audio yang diizinkan: .wav, .mp3, .m4a, .webm, .weba</span> */}
                                            <ReactDropzone
                                                multiple={false}
                                                accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg, .m4a, .webm, .weba"
                                                onDrop={this.onPreviewDrop}
                                                style={reactDropzoneStyle}
                                            >
                                                <div style={{display: 'flex'}}>
                                                    <button id="edit-start-online-enroll" type="button" className="btn btn-primary btn-rounded" style={{marginRight: '10px', borderRadius: '50px'}}>Pilih File</button>
                                                </div>
                                            </ReactDropzone>
                                        </div>
                                    </div>
                                :
                                    <div style={{marginTop: '5px'}}>
                                        <div onClick={this.recordingStatus.bind(this)}>
                                            <RecordingAudio
                                                category="enroll_verif"
                                                resultBlob={this.getResultBlob}
                                            />
                                        </div>
                                    </div>
                                }
                                <div style={{marginTop: '12px'}}>
                                    {this.state.fileAudio?
                                        <div
                                            alt="Preview"
                                            key={this.state.namaFile}
                                        >
                                            File: {this.state.namaFile}
                                            <br/>
                                            
                                        </div>
                                    :""}
                                </div>
                                {this.state.inputFileName ?
                                    <audio id="edit-preview-audio" src={this.state.inputFileName} controls style={{marginTop: '10px'}}></audio>
                                :
                                    ""
                                }
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            { this.state.inputName && this.state.inputFileName ?
                                <div>
                                    <Button 
                                        bsStyle="primary"
                                        onClick={this.submitModal.bind(this)}
                                    >
                                        Tambahkan
                                    </Button>
                                    <Button onClick={close}>Tutup</Button>
                                </div>
                                :
                                <div>
                                    <Button bsStyle="primary" disabled>Tambahkan</Button>
                                    <Button onClick={close}>Tutup</Button>
                                </div>
                                
                            }
                        </Modal.Footer>
                    </Modal>
                :""}
                {this.props.categoryModal==="verify"?
                    <Modal
                        show={this.state.show}
                        onHide={close}
                        container={this}
                        aria-labelledby="contained-modal-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title">Verifikasi Speaker {this.props.nameSpeaker}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row style={{ marginTop: '25px'}}>
                                {!this.state.fileAudio ?
                                    <div>
                                        <Col lg={5} sm={5} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                            {this.state.isRecording ?
                                                <div>
                                                    <ReactDropzone
                                                        multiple={false}
                                                        accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg"
                                                        onDrop={this.onPreviewDrop}
                                                        style={reactDropzoneStyle}
                                                        disabled
                                                    >
                                                    <div className="button-rec-hover">
                                                        <button className="button-circle-disabled">
                                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24px" height="24px" viewBox="-0.5 -0.5 71 71"><defs/>
                                                            <g>
                                                                <path d="M 10 9.25 L 37.67 9.25 L 37.67 0 L 60 25 L 37.67 50 L 37.67 40.75 L 10 40.75 L 10 25 Z" fill="#ffffff" stroke="none" transform="rotate(270,35,25)" pointerEvents="none"/>
                                                                <rect x="0" y="60" width="70" height="10" fill="#ffffff" stroke="none" pointerEvents="none"/>
                                                            </g>
                                                            </svg>
                                                            <span className="button-font">Unggah Audio</span>
                                                        </button>
                                                    </div>
                                                    </ReactDropzone>
                                                </div>
                                            :
                                                <div>
                                                    <ReactDropzone
                                                        multiple={false}
                                                        accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg"
                                                        onDrop={this.onPreviewDrop}
                                                        style={reactDropzoneStyle}
                                                    >
                                                    <div className="button-rec-hover">
                                                        <button className="button-circle button-style">
                                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24px" height="24px" viewBox="-0.5 -0.5 71 71"><defs/>
                                                            <g>
                                                                <path d="M 10 9.25 L 37.67 9.25 L 37.67 0 L 60 25 L 37.67 50 L 37.67 40.75 L 10 40.75 L 10 25 Z" fill="#ffffff" stroke="none" transform="rotate(270,35,25)" pointerEvents="none"/>
                                                                <rect x="0" y="60" width="70" height="10" fill="#ffffff" stroke="none" pointerEvents="none"/>
                                                            </g>
                                                            </svg>
                                                            <span className="button-circle-font button-font">Unggah Audio</span>
                                                        </button>
                                                    </div>
                                                    </ReactDropzone>
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={2} sm={2} xs={12} style={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
                                            <div>
                                                Atau
                                            </div>
                                        </Col>
                                        <Col lg={5} sm={5} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                            <div onClick={this.recordingStatus.bind(this)}>
                                                <RecordingAudio
                                                    category="verification"
                                                    resultBlob={this.getResultBlob}
                                                />
                                            </div>
                                        </Col>
                                    </div>
                                :""}
                            </Row>
                            <div>
                                {this.state.fileAudio?
                                    <div
                                        alt="Preview"
                                        key={this.state.namaFile}
                                    >
                                        {this.state.namaFile}
                                        <br/>
                                    </div>
                                :""}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            { this.state.inputFileName ?
                                <div>
                                    <Button 
                                        bsStyle="primary"
                                        onClick={this.verifySpeaker.bind(this)}
                                    >
                                        Verifikasi
                                    </Button>
                                    <Button onClick={close}>Tutup</Button>
                                </div>
                                :
                                <div>
                                    <Button bsStyle="primary" disabled>Verifikasi</Button>
                                    <Button onClick={close}>Tutup</Button>
                                </div>
                                
                            }
                        </Modal.Footer>
                    </Modal>
                :""}
                {this.state.result_is_verified?
                    <Modal
                        show={this.state.showRes}
                        onHide={close}
                        container={this}
                        aria-labelledby="contained-modal-title"
                    >
                        <Modal.Header style={{borderBottom: 'none'}}>
                            <Modal.Title style={{textAlign: 'center'}} id="contained-modal-title">Verifikasi Speaker {this.props.nameSpeaker}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="button-circle-font" style={{textAlign: 'center', fontSize: '16px'}}>{this.state.result_is_verified}</div>
                        </Modal.Body>
                        <Modal.Footer style={{borderTop: 'none'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button onClick={closeRes}>Tutup</Button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                :""}
            </div>
        );
    }
}

const reactDropzoneStyle = {
    borderStyle: 'none',
};

export default AddVoicesModal;
