// import React from "react";
// import ReactDOM from "../pages/SpeechToText";

import RecordRTC from "recordrtc";
import { MonoAudioRecorder } from "recordrtc";
// import { MonoAudioRecorder, StereoAudioRecorder } from "recordrtc";
import hark from "hark";

function Decode(_config) {
  var configDefault = {
    server: {
      "bca" : "/v1/asr/bca/transcript",
      "gabungan-hmm-001" : "/v1/asr/gabungan-hmm-001/transcript",
      "kemenlu" : "/v1/asr/kemenlu/transcript",
      "stm-tdnn": "/v1/asr/stm-tdnn/transcript",
      "telkom": "/v1/asr/telkom/transcript",
      "talkshow": "/v1/asr/talkshow/transcript",
      "mk-ky": "/v1/asr/mk-ky/transcript",
    },
    contentType:
      "content-type=audio/x-raw,+layout=(string)interleaved,+rate=(int)16000,+format=(string)S16LE,+channels=(int)1",
    bufferSize: 8192,
    inputChannels: 1,
    outputChannels: 1
  };

  this.speechEvents = null;
  this.isUpload = false;
  var config = _config;
  this.serverUpload = {
    "bca" : "/v1/asr/bca/transcript",
    "gabungan-hmm-001" : "/v1/asr/gabungan-hmm-001/transcript",
    "kemenlu" : "/v1/asr/kemenlu/transcript",
    "stm-tdnn": "/v1/asr/stm-tdnn/transcript",
    "telkom": "/v1/asr/telkom/transcript",
    "talkshow": "/v1/asr/talkshow/transcript",
    "mk-ky": "/v1/asr/mk-ky/transcript",
  };
  this.model = "bca";

  // SERVER SETTINGS
  this.server = config.server || configDefault.server;

  // AUDIO SETTINGS
  this.contentType = config.contentType || configDefault.contentType;
  this.bufferSize = config.bufferSize || configDefault.bufferSize;
  this.inputChannels = config.inputChannels || configDefault.inputChannels;
  this.outputChannels = config.outputChannels || configDefault.outputChannels;
  this.sampleRate = 16000;
  this.AudioContext = window.AudioContext || window.webkitAudioContext;

  // MICROPHONE VARIABLES
  this.recorder = null;
  this.requestedAccess = false;
  
  // MICROPHONE METHODS  
  this.blobs = [];
  this.record = function () {
      if (!navigator.mediaDevices.getUserMedia || this.requestedAccess) return;
      this.requestedAccess = true;
      console.log("Init recording");
      navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(this.onMediaStream.bind(this))
      .catch(function(error) {
          alert('Unable to access your microphone.');
          console.error(error)
      });
  };

  this.onMediaStream = async function (microphone) {
    console.log('microphone: ', microphone)
    var options = {};
    this.speechEvents = hark(microphone, options);
    this.blobs = [];
    var that = this

    this.recorder = RecordRTC(microphone, {
        type: 'audio',
        mimeType: 'audio/webm',
        recorderType: MonoAudioRecorder,
        desiredSampRate: this.sampleRate,
    });
    
    // release microphone on stopRecording
    this.recorder.microphone = microphone;

    this.speechEvents.on('speaking', function() {
      console.log('speaking');
      that.recorder.startRecording();
    });

    this.speechEvents.on('stopped_speaking', async function() {
      that.recorder.stopRecording(function() {
        let blob = that.recorder.getBlob();
        console.log("this model from recording: ", that.model)
        console.log("make id string ", 'audio_'+that.makeid(5)+'.webm')
        that.PostBlob(blob, that.model, 'audio_'+that.makeid(5)+'.webm', 111);
      });

      console.log('stopped_speaking');
    });
  }

  this.stopRecordingCallback = function () {
      var blob = this.recorder.getBlob();
      var url = URL.createObjectURL(blob);
      console.log("blob url: ", url);
      this.recorder.microphone.stop();
      this.requestedAccess = false;
  }

  this.stop = function() {
    if(this.speechEvents == null) return;
    this.speechEvents.stop();
    this.recorder.microphone.stop();
    this.requestedAccess = false;

      // if (!this.recorder) return;
      // this.disabled = true;
      // this.recorder.stopRecording(this.stopRecordingCallback.bind(this));
      // if (this.ws) {
      //   var state = this.ws.readyState;
      //   if (state === 1) {
      //     this.ws.close();
      //     console.log("WS end stream");
      //     // this.ws.send(new Float32Array(0));
      
      //   }
      // }
  };


  var workerPath = window.location.origin+'/lib/ffmpeg_asm.js';
  function processInWebWorker() {
      var blob = URL.createObjectURL(new Blob(['importScripts("' + workerPath + '");var now = Date.now;function print(text) {postMessage({"type" : "stdout","data" : text});};onmessage = function(event) {var message = event.data;if (message.type === "command") {var Module = {print: print,printErr: print,files: message.files || [],arguments: message.arguments || [],TOTAL_MEMORY: message.TOTAL_MEMORY || false};postMessage({"type" : "start","data" : Module.arguments.join(" ")});postMessage({"type" : "stdout","data" : "Received command: " +Module.arguments.join(" ") +((Module.TOTAL_MEMORY) ? ".  Processing with " + Module.TOTAL_MEMORY + " bits." : "")});var time = now();var result = ffmpeg_run(Module);var totalTime = now() - time;postMessage({"type" : "stdout","data" : "Finished processing (took " + totalTime + "ms)"});postMessage({"type" : "done","data" : result,"time" : totalTime});}};postMessage({"type" : "ready"});'], {
          type: 'application/javascript'
      }));
      var worker = new Worker(blob);
      URL.revokeObjectURL(blob);
      return worker;
  }
  var worker;

  console.log("worker", worker);

  this.convertFile = function(fileBlob, model, filename) {
    console.log("file blob", fileBlob);
    console.log("model to decode", model);
    console.log("name of file", filename);
    var readedFile;
    var buffersReady;
    // var workerReady;
    var fileReader = new FileReader();
    fileReader.onload = function() {
      readedFile = this.result;
      postMessage();
    };

    fileReader.readAsArrayBuffer(fileBlob);

    console.log("file reader", fileReader)
    
    if (!worker) {
      console.log('<a href="'+ workerPath +'" download="ffmpeg-asm.js">ffmpeg-asm.js</a> file download started. It is about 18MB in size; please be patient!');
      worker = processInWebWorker();
    }
    var that = this;
    worker.onmessage = function(event) {
        var message = event.data;
        console.log("onMessage", message);
        if (message.type === "ready") {
            console.log('<a href="'+ workerPath +'" download="ffmpeg-asm.js">ffmpeg-asm.js</a> file has been loaded.');
            // workerReady = true;
            if (buffersReady)
                postMessage();
        } else if (message.type === "stdout") {
            console.log(message.data);
        } else if (message.type === "start") {
            console.log('<a href="'+ workerPath +'" download="ffmpeg-asm.js">ffmpeg-asm.js</a> file received ffmpeg command.');
        } else if (message.type === "done") {
            console.log(JSON.stringify(message));
            var result = message.data[0];
            console.log(JSON.stringify(result));
            var blob = new File([result.data], 'test.ogg', {
                type: 'audio/ogg'
            });
            console.log(JSON.stringify(blob));
            console.log("to postblob in convert file", blob)
            that.PostBlob(blob, model, filename, 222);
        } else if (message.type === "exit") {
          console.log("Process exited with code " + message.data);
          worker.terminate();
        } else {
          console.log(message.type);
        }
    };

    var postMessage = function() {
      console.log("post message");
      try {
        worker.postMessage({
            type: 'command',
            arguments: '-i audio.wav output.ogg'.split(' '),
            files: [
                {
                    data: new Uint8Array(readedFile),
                    name: "audio.wav"
                }
            ]
        });
      } catch (e) {
        console.log(e);
      }
      console.log("done post message");
    };
  }

  this.PostBlob = function(blob, model, filename) {
    console.log("blob postblob", blob);
    var url = URL.createObjectURL(blob);
    console.log("url postblob", url);

    //make form request
    var fd = new FormData();
    fd.append(filename, blob);

    this.serverUploadModel = this.serverUpload[model]; 
    console.log("model map: ", this.serverUploadModel)

    const options = {
      method: 'POST',
      body: fd,
    };

    fetch(this.serverUploadModel, options)
    .then(r => r.json()
    .then(data => {
      console.log("data result decode ", data);
      console.log("data length: ", Object.keys(data).length);
      if(data['message']){
        let result = [{text:"Error message: " + data['message']}]
        console.log("data result err: ", data['message']);
        config.onResult([result], "error_blob", filename);
        this.isUpload = false;
      } else if (Object.keys(data).length > 0) {
        console.log("data result text: ", data);
        config.onResult(data, url, filename);
        // config.onBlobUrl(url);
        this.isUpload = false;
      }
    }).catch(e => {
      let result = [{text:"Error message: " + e}]
      console.log("data result err: ", e);
      config.onResult([result], "error_blob", filename);
      this.isUpload = false;
    })).catch(e=> {
      let result = [{text:"Error message: " + e}]
      console.log("data result err: ", e);
      config.onResult([result], "error_blob", filename);
      this.isUpload = false;
    });
  };

  this.uploadFile = function(fileBlob, model, filename) {
    this.isUpload = true;
    this.convertFile(fileBlob, model, filename);
  };
  
  this.getModel = function(model) {
    console.log("model: ", model);
    this.model = model;
  }

  this.makeid = function(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
 
}

export default Decode;
