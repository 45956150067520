import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import Home from './pages/Home';
import SpeechToText from './pages/SpeechToText';
// import Registration from './pages/SpeechRegistration';
import TextToSpeech from './pages/TextToSpeech';
import SpeakerBiometric from './pages/SpeakerBiometric';
import './App.css';


class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <Switch>
            <Route path="/" exact render={props => <SpeechToText{...props}/>} />
              {/* <Route path="/speechtotext" exact render={props => <SpeechToText{...props} />} /> */}
              <Route path="/speechtotext" exact render={props => <SpeechToText{...props}/>} />
              {/*<Route path="/speechregistration" exact render={props => <Registration{...props} />} />*/}
              <Route path="/texttospeech" exact render={props => <TextToSpeech{...props}/>} />
              <Route path="/speakerbiometric" exact render={props => <SpeakerBiometric{...props}/>} />
          </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
