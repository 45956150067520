function Synthesize(_config) {
    var configDefault = {
        server: "ws://43.245.191.195:8080/synthesize",
        bufferSize: 8192
    };

    var config = _config;

    // SERVER SETTINGS
    this.server = config.server || configDefault.server;
    
    function waitForSocketConnection(socket, callback) {
        setTimeout(function() {
          if (socket.readyState === 1) {
            console.log("Connection is made");
            if (callback != null) {
              callback(socket);
            }
            return;
          } else {
            console.log("wait for connection...");
            waitForSocketConnection(socket, callback);
          }
        }, 1000); // wait 5 milisecond for the connection...
    }

    this.send = function(text) {
        console.log(text);
        this.ws = this.createWebSocket();
        waitForSocketConnection(this.ws, function(ws) {
            var state = ws.readyState;
            console.log("state " + state);
            if (state === 1) {
                console.log("WS send text");
                ws.send(text);
            }
        });
    }
    this.onError = function(e) {
        console.log("onError");
    };

    this.closeWebSocket = function() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    };

    // WEBSOCKET
    this.createWebSocket = function() {
        console.log("WS connecting");
        var url = this.server;
        var ws = new WebSocket(url);
        ws.binaryType = "arraybuffer";

        ws.onmessage = function(msg) {
            var data = msg.data;
            console.log(data);
        };

        ws.onopen = function() {
            try {
                console.log("OPEN");
            } catch (e) {}
        };

        ws.onclose = function(msg) {
            var code = msg.code;
            var reason = msg.reason;
            var wasClean = msg.wasClean;
            console.log("WS closed: " + code + "/" + reason + "/" + wasClean);
        };

        ws.onerror = function(e) {
            console.log("WS error: " + e.data);
            config.onError(e);
        };

        return ws;
    };
}


export default Synthesize;
