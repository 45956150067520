import React from "react";
import { Button } from "react-bootstrap";

function TranscriptList({recordItems, playingStatus, playPauseAudio, saveTranscript}) {
  const items = recordItems
  return (
    <div id="Table" style={{ marginTop: '4rem', width: '80%' }}>
      { items.length > 0 ?
        (
        <div className="model-title">
          Transkrip
        </div>
        ): ''
      }
      <table>
        <tbody>
          {items.map((item, key) => {
            return (
              <tr key={key}>
                {/* <td> key {key}</td> */}
                {/* <td>{item.filename}</td> */}
                {/* <td style={{ paddingBottom: '1rem'}}>
                  {item.start.toFixed(2)} - {item.end.toFixed(2)}
                </td> */}
                <td className="button-result" style={{ marginBottom: '1rem' }}>
                  {item.text}
                </td>
                <td style={{paddingBottom: '1rem'}}>
                  <Button
                    bsStyle="primary"
                    style={{ marginLeft: "24px", paddingLeft: "16px", paddingRight: "16px", borderRadius: '20px' }}
                    onClick={saveTranscript}
                  >
                    Simpan
                  </Button>
                </td>
                {/* {item.audioSrc === "error_blob" ?
                  ""
                  :
                  <td style={{paddingBottom: '1rem'}}>
                      <audio src={item.audioSrc} controls style={{height: '40px', width: '250px', marginLeft: '20px'}}></audio>
                      <i title="Putar" onClick={() => playPauseAudio()} className={`fa ${ playingStatus === 1 ? 'fa-pause':'fa-play'} icon-configuration-verify`} aria-hidden="true" style={{paddingLeft: '25px', color: 'rgba(0, 127, 140, 0.8)'}}></i>
                      {
                        this.props.playingStatus === 1 ? (
                          <i title="Berhenti" onClick={() => this.props.stopAudio()} className="fa fa-stop icon-configuration-verify" aria-hidden="true" style={{paddingLeft: '5px', color: 'rgba(0, 127, 140, 0.8)'}}></i>
                        ): ''
                      }
                  </td>
                } */}
                {/* <td style={{paddingBottom: '1rem'}}>
                    <i title="Hapus" onClick={() => this.props.deleteRecordTranscript(key)} className="fa fa-trash icon-configuration-verify" aria-hidden="true"></i>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TranscriptList;