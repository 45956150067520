import React, { Component } from 'react';
// import ProsaLogo from '../assets/logo-white.svg';
// import styled from 'styled-components';
import Profil from '../assets/profile.jpg';
import { Link } from 'react-router-dom';
import LogoProsa from '../assets/logo-new-white.png';

class Header extends Component {
    constructor(props) {
        super();
        this.state = {
            currentActive: props.name
        };
        this.setActive = this.setActive.bind(this);
    }
    setActive(category) {
        this.setState({
            currentActive: category
        });

    }
    render () {
        let activePath = "stt";
        if (window.location.pathname === '/speechtotext') {
            activePath = 'stt';
        } else if (window.location.pathname === '/texttospeech') {
            activePath = 'tts';
        } else if (window.location.pathname === '/speakerbiometric') {
            activePath = 'sb';
        }
        return (
            // <StyledHeader>
            //     {/* <a href="/">
            //         <ImgLogo src={ProsaLogo}></ImgLogo>
            //     </a> */}
            //     <NavBar>
            //         {/* <li className={(this.state.currentActive === "dialog") ? "active" : ""}>
            //             <a href="/">
            //                 System Dialogue
            //             </a>
            //         </li> */}
            //         <li className={(this.state.currentActive === "speechtotext") ? "active" : ""}>
            //             <a href="/speechtotext">
            //                 Speech-to-Text
            //             </a>
            //         </li>
            //         {/* <li className={(this.state.currentActive === "speechregistration") ? "active" : ""}>
            //             <a href="/speechregistration">
            //                 Speech Registration
            //             </a>
            //         </li> */}
            //         <li className={(this.state.currentActive === "texttospeech") ? "active" : ""}>
            //             <a href="/texttospeech">
            //                 Text-to-Speech
            //             </a>
            //         </li>
            //     </NavBar>
            // </StyledHeader>
            <div>
                <nav className="navbar navbar-inverse navbar-fixed-top">
                    <div className="container-fluid" style={{marginLeft: '17%'}}>
                        <div className="navbar-header">
                            {/* <a className="navbar-brand" href="#">WebSiteName</a> */}
                        </div>
                        <ul className="nav navbar-nav">
                            {/* <li className="active"><a href="#">Home</a></li>
                            <li><a href="#">Page 1</a></li>
                            <li><a href="#">Page 2</a></li>
                            <li><a href="#">Page 3</a></li> */}
                            <img className="avatar" alt="profile" src={Profil}></img>
                        </ul>
                    </div>
                </nav>

                <div className="sidenav" style={{background: 'linear-gradient(to bottom, #00b38f, #009894, #007d8c, #116276 , #2f4858)'}}>
                    <img className="logo-prosa" alt="logo" src={LogoProsa}></img>
                    {/* <div style={{ minHeight: '50px', marginBottom: '30px' }}></div> */}
                    {activePath === 'stt'?
                        <div>
                            <div style={{backgroundColor: '#116276'}}>
                                <Link to="/speechtotext">
                                    Speech to Text
                                </Link>
                            </div>
                            <div>
                                <Link to="/texttospeech">
                                    Text to Speech
                                </Link>
                            </div>
                            {/* <div>
                                <Link to="/speakerbiometric">
                                    Speaker Biometric
                                </Link>
                            </div> */}
                        </div>
                    : ""
                    }
                    {activePath === 'tts'?
                        <div>
                            <div>
                                <Link to="/speechtotext">
                                    Speech to Text
                                </Link>
                            </div>
                            <div style={{backgroundColor: '#116276'}}>
                                <Link to="/texttospeech">
                                    Text to Speech
                                </Link>
                            </div>
                            {/* <div>
                                <Link to="/speakerbiometric">
                                    Speaker Biometric
                                </Link>
                            </div> */}
                        </div>
                    : ""
                    }
                    {activePath === 'sb'?
                        <div>
                            <div>
                                <Link to="/speechtotext">
                                    Speech to Text
                                </Link>
                            </div>
                            <div>
                                <Link to="/texttospeech">
                                    Text to Speech
                                </Link>
                            </div>
                            {/* <div style={{backgroundColor: '#116276'}}>
                                <Link to="/speakerbiometric">
                                    Speaker Biometric
                                </Link>
                            </div> */}
                        </div>
                    : ""
                    }
                </div>
                
            </div>
        )
    }
}


// const NavBar = styled.ul`
//     color: white;
//     margin-left: auto;
//     margin-right: 180px;
//     li {
//         padding-top: 1em;
//         display:table-cell;
//     }
//     li + li:before {
//         font-weight: lighter;
//         color: white;
//         content: " ";
//         padding: 0 25px;
//     }
//     li a {
//         font-family: 'Titillium Web', sans-serif;
//         font-size: 1.5em;
//         color: white;
//         text-decoration: none;
//     }
//     .active {
//         a {
//             font-weight: bold;
//             padding-bottom: 0.5em;
//             border-bottom: 7px solid white;
//         }
//     }
// `;

// const ImgLogo = styled.img`
//     height: 6em;
//     margin-left: 100%;
//     @media (max-height: 801px) {
//         height: 5em;
//         padding-top: 1em;  
//     }
//     @media (min-height: 801px) {
//         height: 6em;
//     }
// `;

// const StyledHeader = styled.div`
//     display: flex;
//     flex-direction: column:
//     align-items: center;
//     background-color: #191919;
//     width: 100%;
    
//     @media (max-height: 801px) {
//         height: 4.3em;
//     }
//     @media (min-height: 801px) {
//         height: 4.4em;
//         // padding-top: px;
//     }
// `;

export default Header